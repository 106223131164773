import { Modal } from "react-bootstrap";
import { CommonUtil } from "../../utils/CommonUtil";
import { Constants } from "../Languages/Constants";
import DraggableComponent from "../../Components/DraggableComponent";
import { useState } from "react";
import FileImport from "../../Components/FileImport";

const ImportReservation = (props: any) => {
  const FILETYPE = "RESERVATION_CREATION";
  enum POPUPSTATUS {
    OPEN = "open",
    CLOSE = "close",
    SAVE = "save",
    FAIL = "fail",
    ERROR="error"
  }

  const [showPopup, setShowPopup] = useState<POPUPSTATUS>(POPUPSTATUS.CLOSE);

  const importFile = () => {
    setShowPopup(POPUPSTATUS.OPEN);
  };
  const closeModals = () => {
    setShowPopup(POPUPSTATUS.CLOSE);
  };
  const onClickUpdate = () => {
    setShowPopup(POPUPSTATUS.SAVE);
  };

  const downLoadCSV = (path: any, name: any) => {
    let link = document.createElement("a");
    link.download = name;
    link.href = window.location.origin + "/" + path;
    link.click();
  };

  const handleRequest = (response: any) => {
    if (String(response.status).toLocaleLowerCase() === "success") {
      setShowPopup(POPUPSTATUS.CLOSE);
      // props?.onSuccess();
    } else {
      setShowPopup(POPUPSTATUS.FAIL);
    }
  };

  return (
    <>
      <Modal
        show={showPopup !== POPUPSTATUS.CLOSE}
        dialogAs={DraggableComponent}
        size="lg"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title">
              {CommonUtil.getMessageText(
                Constants.RESERVATIONS_IMPORT,
                "Import Reservation"
              )}
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeModals}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="master-data">
              <div className="col-12 mb-3">
                <label className="col-sm-12 col-form-label">
                  <div
                    style={{ color: "blue", textDecoration: "underline" }}
                    
                  >
                    <img
                      src="../images/importsample.png"
                      alt="sample"
                      width={"40px"}
                      style={{ marginRight: "9px" }}
                    />
                    <span onClick={() => {
                      downLoadCSV(
                        "docs/ReservationImport.xlsx",
                        "ReservationImport"
                      );
                    }}>{CommonUtil.getMessageText(
                      Constants.SAMPLE_CSV_FILE,
                      "Sample xlsx file"
                    )}</span>
                  </div>
                </label>
              </div>
              {
                <FileImport
                  status={showPopup}
                  onError={()=>setShowPopup(POPUPSTATUS.ERROR)}
                  onRequestCall={handleRequest}
                  uploadUrl={`api/upload/importdata?importType=${FILETYPE}&saasOrgId=${CommonUtil.getSaasOrgId()}`}
                  includeColumn="reservationCode"
                />
              }
            </div>
            {/* <p className="mb-1 mt-2" style={{}}>
              <b>Note: </b>Asset owners take responsibility for the requirements
              of their Carrier list, vHub is held harmless against any claims of
              Carrier compliance
            </p> */}
          </div>
          <div className="modal-footer clsModalPermissionsFooter">
            <button
              type="button"
              className="btn btn-outline-dark"
              data-bs-dismiss="modal"
              onClick={closeModals}
            >
              {CommonUtil.getMessageText(Constants.CLOSE, "Cancel")}
            </button>
            &nbsp;&nbsp;
            <button
              type="button"
              className="btn btn-success"
              onClick={onClickUpdate}
            >
              {CommonUtil.getMessageText(Constants.IMPORT, "Import")}
            </button>
          </div>
        </div>
      </Modal>

      <div className="">
        {/* <button
          type="button"
          onClick={importFile}
          className="btn btn-primary waves-effect waves-light"
        >
          {CommonUtil.getMessageText(Constants.IMPORT, "Import")}
        </button> */}
        <button className={`btn btn-primary waves-effect waves-light`} onClick={importFile}>
          {CommonUtil.getMessageText(Constants.IMPORT, "Import")}
        </button>
      </div>
    </>
  );
};

export default ImportReservation;
