import React, { useEffect, useRef, useState } from "react";
import { CommonUtil, setSectionCompleted } from "../../utils/CommonUtil";
import { Constants } from "../Languages/Constants";
import { NetworkManager } from "../../Components/NetworkManager";
import SingleSelectComp from "../../Components/SingleSelectComp";
import ToastManager from "../../Components/ToastManager";
import CDTextarea from "../Equipments/CDTextarea";

const AddOpenAccount = ({updateProfile}:any) => {
  const [cardEdit, setCardEdit] = useState(true);
  const [accountId, setAccountId] = useState("");
  const [glNumber, setglNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountType, setAccountType] =  useState({key:"",value:""});
  const [accountDescription, setAccountDescription] = useState("");
  const [accountStatus, setAccountStatus] = useState({key:"",value:""});
  const [accountLimit, setAccountLimit] = useState("");

  const field1:any = useRef();
  const field2:any = useRef();
  const field3:any = useRef();
  const field4:any = useRef();
  const field5:any = useRef();

  const onClickDisable = () => {
    setCardEdit(false);
  };
  const onClickSave = () => {    
    updateOpenAccounts();
  };
  const onClickEdit = () => {
    setCardEdit(true);
  };

  const updateOpenAccounts = ()=>{
    if(!accountNumber || !accountType?.key || !accountDescription || !accountStatus?.key || !accountLimit){ 
      ToastManager.showToast(CommonUtil.getMessageText(Constants.PLEASE_ENTER_MANDATORY_FIELDS, "Please enter mandatory fields"),
        "Error"
      );
      return;}
    let restData:any = {
      "accountNumber": accountNumber,
      "accountName": accountName,
      "glNumber": glNumber,
      "accountType": accountType?.key,
      "accountDescription": accountDescription,
      "accountStatus": accountStatus?.key,
      "accountLimit": accountLimit
    }
    let url = "openAccounts/?saasOrgId=" + CommonUtil.getSaasOrgId()
    if(accountId){
      restData.accountId = accountId;
      url = "openAccounts/update?accountId="+accountId;
    }
    
    NetworkManager.sendJsonRequest(
      url,
      restData,
      "",
      accountId ? "PUT" : "POST",
      true,
      onUpdateOpenAccounts,
      onLoginError);
  }
  const onUpdateOpenAccounts = (dataObj:any)=>{
    if (dataObj) {
      var restData = JSON.parse(dataObj);
      if (restData) {
        ToastManager.showToast(
          CommonUtil.getMessageText(
            Constants.UPDATED_SUCCESSFULLY,
            "Updated Successfully"
          ),
          "Info"
        );
        setCardEdit(false);
        setSectionCompleted("openAccount", updateProfile);
      }
    }
  }
  const getOpenAccounts = ()=>{
    NetworkManager.sendJsonRequest(
      "openAccounts/?saasOrgId=" + CommonUtil.getSaasOrgId(),
      "",
      "",
      "GET",
      true,
      onGetOpenAccounts,
      onLoginError);
  }
  

  const onGetOpenAccounts = (dataObj:any)=>{
    if (dataObj) {
      var restData = JSON.parse(dataObj);
      if (restData) {
        setAccountId(restData.accountId);
        setAccountNumber(restData.accountNumber);
        setAccountName(restData.accountName);
        setglNumber(restData.glNumber);
        setAccountLimit(restData.accountLimit);
        setAccountStatus({key:restData.accountStatus,value:restData.accountStatus});
        setAccountType({key:restData.accountType,value:restData.accountType});
        setAccountDescription(restData.accountDescription);
      }
    }
  }
 
  const onLoginError = (err: any) => {
    console.log(err);
  };

  useEffect(()=>{
    getOpenAccounts();
  },[])

  const bankClass1 = !cardEdit ? "disable-form" : "enable-form"
  return (
    <div className="row">
      
      <div className="col-sm-12">
        <div className="row">
          <div className="col-sm-8">
            <h6>
              {CommonUtil.getMessageText(
                Constants.OPEN_ACCOUNT,
                "Open Account"
              )}
            </h6>
          </div>
          <div className="col-sm-4 text-right d-none">
            <button
              type="button"
              className="btn btn-danger btn-save js-save"
              style={{ display: cardEdit ? "" : "none" }}
              onClick={onClickDisable}
            >
              {CommonUtil.getMessageText(Constants.CANCEL, "Cancel")}
            </button>
            &nbsp;
            <button
              type="button"
              className="btn btn-primary btn-save js-save"
              style={{ display: cardEdit ? "" : "none" }}
              onClick={onClickSave}
            >
              {CommonUtil.getMessageText(Constants.SAVE, "Save")}
            </button>
            &nbsp;
            <button
              type="button"
              className="btn btn-warning btn-edit js-edit"
              style={{ display: cardEdit ? "none" : "" }}
              onClick={onClickEdit}
            >
              {CommonUtil.getMessageText(Constants.EDIT, "Edit")}
            </button>
          </div>
        </div>
      </div>
      <div className="col-sm-12">
        <form className={bankClass1}>
          <div className="row">
          <div className="col-md-4 col-12">
              <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                  {CommonUtil.getMessageText(
                    Constants.NAME_ACCOUNT,
                    "Account Name"
                  )}
                </label>
                <div className="col-sm-12">
                  <input
                    ref={field4}
                    type="text"
                    className="form-control clsFormInputControl"
                    placeholder=""
                    value={accountName}
                    maxLength={500}
                    onChange={(e:any) => {
                      setAccountName(e.currentTarget.value);
                      CommonUtil.isMandatory(field4);
                    }}
                  />
                </div>
              </div>
            </div>
           
            <div className="col-md-4 col-12">
              <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                  {CommonUtil.getMessageText(
                    Constants.TYPE,
                    "Type"
                  )}
                </label>
                <div className="col-sm-12">
                  {/* <input
                    ref={field2}
                    type="text"
                    className="form-control clsFormInputControl"
                    placeholder=""
                    value={accountType}
                    maxLength={500}
                    onChange={(e:any) => {
                      setAccountType(e.currentTarget.value);
                      CommonUtil.isMandatory(field2);
                    }}
                  /> */}
                  <SingleSelectComp dataSource={[{key:"Cost Account",value:"Cost Account"},{key:"Receivable",value:"Receivable"}]} selectedValue={accountType}
                                                    handleChange={(data:any)=>{
                                                      setAccountType(data?data:accountType);
                                                    }} value={"key"} label={"value"} />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                  {CommonUtil.getMessageText(
                    Constants.ACC_NUMBER,
                    "Account Number"
                  )}
                </label>
                <div className="col-sm-12">
                  <input
                    ref={field1}
                    type="text"
                    className="form-control clsFormInputControl"
                    placeholder=""
                    value={accountNumber}
                    maxLength={500}
                    onChange={(e:any) => {
                      setAccountNumber(e.currentTarget.value);
                      CommonUtil.isMandatory(field1);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                  {CommonUtil.getMessageText(
                    Constants.GL_NUMBER,
                    "GL Number"
                  )}
                </label>
                <div className="col-sm-12">
                  <input
                    ref={field5}
                    type="text"
                    className="form-control clsFormInputControl"
                    placeholder=""
                    value={glNumber}
                    maxLength={500}
                    onChange={(e:any) => {
                      setglNumber(e.currentTarget.value);
                      CommonUtil.isMandatory(field5);
                    }}
                  />
                </div>
              </div>
            </div>
            
            
            <div className="col-md-4 col-12">
              <div className="form-group row">              
                <label className="col-sm-12 col-form-label">
                  {CommonUtil.getMessageText(
                    Constants.STATUS,
                    "Account Status"
                  )}
                </label>
                <div className="col-sm-12">
                 
                  <SingleSelectComp dataSource={[{key:"Active",value:"Active"},{key:"Inactive",value:"Inactive"}]} selectedValue={accountStatus} 
                                                    handleChange={(data:any)=>{
                                                      setAccountStatus(data?data:accountStatus);
                                                    }} value={"key"} label={"value"} />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                  {CommonUtil.getMessageText(
                    Constants.CREDIT_AMOUNT,
                    "Credit Amount"
                  )}
                </label>
                <div className="col-sm-12">
                  <input
                    ref={field5}
                    type="text"
                    className="form-control clsFormInputControl"
                    placeholder=""
                    value={accountLimit}
                    maxLength={500}
                    onChange={(e:any) => {
                      setAccountLimit(e.currentTarget.value);
                      CommonUtil.isMandatory(field5);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                  {CommonUtil.getMessageText(
                    Constants.DESCRIPTION,
                    "Description"
                  )}
                </label>
                <div className="col-sm-12">
                <CDTextarea ref={field3}
                                        maxLength={255}
                                        //ref={descRef}
                                        value={accountDescription}
                                        placeholder=""
                                        rows={4}
                                        onChange={(e:any) => {
                                          setAccountDescription(e.currentTarget.value);
                                          CommonUtil.isMandatory(field3);
                                        }}
                                    />
                 
                </div>
              </div>
            </div>

          </div>
          <div className="text-end">
                                <button type="button" className="btn btn-primary waves-effect waves-light" onClick={onClickSave}>Save</button>                              
                                 </div>
        </form>
      </div>
    </div>
  );
};

export default AddOpenAccount;
