import React, { useEffect, useRef, useState } from "react";
import { CommonUtil } from "../../utils/CommonUtil";
import { Constants } from "../Languages/Constants";
import { NetworkManager } from "../../Components/NetworkManager";
import BusyLoader from "../../Components/BusyLoader";
import ToastManager from "../../Components/ToastManager";

const CaptureSalesAgent = () => {
  const cmnRef = useRef<HTMLInputElement>(null);
  const cmeRef = useRef<HTMLInputElement>(null);
  const sanRef = useRef<HTMLInputElement>(null);
  const saeRef = useRef<HTMLInputElement>(null);
  const [accoutData,setAccountData] = useState<any>();
  const [cmn, setCMN] = useState("");
  const [cme, setCME] = useState("");
  const [san, setSAN] = useState("");
  const [sae, setSAE] = useState("");

  let onLoginError = (err: any) => {
    console.log(err);
  };

  let getOrganizationInfo = () => {
    var qParam = CommonUtil.getSaasOrgId();
    var orgUrl = "api/organization/" + qParam;
    NetworkManager.sendJsonRequest(
      orgUrl,
      "",
      "",
      "GET",
      true,
      onOrgDetailsSuccess,
      onLoginError
    );
  };

  let onOrgDetailsSuccess = (dataObj: any) => {
    var restData = JSON.parse(dataObj);
    if (restData) {
        setAccountData(restData);
      setCMN(restData.customerManagerName);
      setCME(restData.customerManagerEmail);
      setSAN(restData.salesAgentName);
      setSAE(restData.salesAgentEmail);
    }
  };

  let onClickSave = (from: string) => {
    let obj: any = accoutData ? {...accoutData}: {};

    if (from === "section") {
      if (!(cmn || cme || san || sae)) return;

      obj.customerManagerName = cmn;
      obj.customerManagerEmail = cme;
      obj.salesAgentName = san;
      obj.salesAgentEmail = sae;
    }

    // console.log(obj);

    BusyLoader.showLoader();
    var orgUrl: string =
      "api/organization/saveOrganizationInformation?saasOrgId=" +
      CommonUtil.getSaasOrgId();
    NetworkManager.sendJsonRequest(
      orgUrl,
      obj,
      "",
      "POST",
      false,
      onOrgUpdateSuccess,
      onLoginError
    );
  };
  let onOrgUpdateSuccess = (dataObj: any) => {
    BusyLoader.hideLoader();
    let strInfo = CommonUtil.getMessageText(Constants.INFO, "Info");
    let strOrgUpdated = CommonUtil.getMessageText(
      Constants.ORG_UPDATED,
      "Organization updated successfully"
    );
    let strError = CommonUtil.getMessageText(Constants.ERROR, "Error");
    //console.log(dataObj)
    if (dataObj) {
      var restData = CommonUtil.parseData(dataObj);
      if (restData) {
        ToastManager.showToast(strOrgUpdated, "Info");
      }
    }
  };

  useEffect(()=>{
    getOrganizationInfo();
  },[]);

  return (
      <div className="row">
        {/* V20-320 now moved according to 3941*/}
        <div className="col-sm-8">
          <h6>
            {CommonUtil.getMessageText(
              Constants.ACCOUNT_MANAGEMENT_DETAILS,
              "Account Management Details"
            )}
          </h6>
        </div>
        <div className="row">
          <div className="col-md-4 col-12">
            <div className="form-group row">
              <label className="col-sm-12 col-form-label">
                {CommonUtil.getMessageText(
                  Constants.CUSTOMER_SUCCESS_MANAGER_NAME,
                  "Customer Success Manager Name"
                )}
              </label>
              <div className="col-sm-12">
                <input
                  ref={cmnRef}
                  type="text"
                  className="form-control "
                  placeholder="Enter Manager Name"
                  aria-label="cmn"
                  value={cmn}
                  disabled={CommonUtil.isRootUser() ? false : true}
                  onChange={(e) => {
                    setCMN(e.currentTarget.value);
                    // CommonUtil.isMandatory(cityRef)
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12">
            <div className="form-group row">
              <label className="col-sm-12 col-form-label">
                {CommonUtil.getMessageText(
                  Constants.CUSTOMER_SUCCESS_MANAGER_EMAIL,
                  "Customer Success Manager Email"
                )}
              </label>
              <div className="col-sm-12">
                <input
                  ref={cmeRef}
                  type="text"
                  className="form-control "
                  placeholder="Enter Manager Email"
                  aria-label="Manager Email"
                  value={cme}
                  disabled={CommonUtil.isRootUser() ? false : true}
                  onChange={(e) => {
                    setCME(e.currentTarget.value);
                    // CommonUtil.isMandatory(cityRef)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-12">
            <div className="form-group row">
              <label className="col-sm-12 col-form-label">
                {CommonUtil.getMessageText(
                  Constants.SALES_MANAGER_NAME,
                  "Sales Manager Name"
                )}
              </label>
              <div className="col-sm-12">
                <input
                  ref={sanRef}
                  type="text"
                  className="form-control "
                  placeholder="Enter Sales Agent Name"
                  aria-label="SAN"
                  value={san}
                  disabled={CommonUtil.isRootUser() ? false : true}
                  onChange={(e) => {
                    setSAN(e.currentTarget.value);
                    // CommonUtil.isMandatory(cityRef)
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12">
            <div className="form-group row">
              <label className="col-sm-12 col-form-label">
                {CommonUtil.getMessageText(
                  Constants.SALES_MANAGER_EMAIL,
                  "Sales Manager Email"
                )}
              </label>
              <div className="col-sm-12">
                <input
                  ref={saeRef}
                  type="text"
                  className="form-control "
                  placeholder="Enter Sales Agent Email"
                  aria-label="sae"
                  value={sae}
                  disabled={CommonUtil.isRootUser() ? false : true}
                  onChange={(e) => {
                    setSAE(e.currentTarget.value);
                    // CommonUtil.isMandatory(cityRef)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <hr/> */}
        <div className="col-md-12 col-12 text-right pt-4 pb-2">
          <button
            type="button"
            style={{ display: "none" }}
            className="btn btn-outline-dark"
            data-dismiss="modal"
          >
            {CommonUtil.getMessageText(
              Constants.DISCARD_CHANGES,
              "Discard Changes"
            )}
          </button>
          &nbsp;&nbsp;
          <button
            type="submit"
            style={{ display: CommonUtil.isRootUser() ? "" : "none" }}
            className="btn btn-primary waves-effect waves-light"
            onClick={(e) => {
              onClickSave("section");
            }}
          >
            {CommonUtil.getMessageText(Constants.SAVE, "Save")}
          </button>
        </div>
      </div>
  );
};

export default CaptureSalesAgent;
