import React from "react";

const CDTextarea = React.forwardRef((props: any, ref:any) => {
  const { value, maxLength = 500, onChange, placeholder, rows,hidemaxLength } = props;
  return (
    <>
      <textarea
        ref={ref}
        maxLength={maxLength}
        value={value}
        className="form-control clsStandardText"
        rows={rows}
        placeholder={placeholder}
        onChange={onChange}
        style={{ height: "100px" }}
      ></textarea>
      <div
        style={{
          alignSelf: "flex-end",
          textAlign: "right",
          padding: "3px",
          color: `${value?.length >= 1000 ? "red" : "black"}`,
        }}
      >
        <span style={{display:hidemaxLength?"none":""}}>{value?.length} / {maxLength}</span>
      </div>
    </>
  );
});

export default CDTextarea;
